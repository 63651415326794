import React from 'react';
import styled from 'styled-components';
import {Text, Heading} from '../../Widgets';
import Image from '../../Components/Image';
import {dateUtil} from '../../Utils/';
import useOrderDisplayState from '../../hooks/use-order-display-state';
const config = require('../../data.json');

export default function TopSection(props) {
  const {order} = props;
  let orderState = useOrderDisplayState({order});

  if (!order) {
    return null;
  }

  return (
    <Wrapper>
      <div className="id-section">
        <Text size="lg" color={config.colors.main} weight="500">
          <span style={{color: config.colors.text}}>
            {order.order_type === 'credit' ? '儲值編號：' : '訂單編號：'}
          </span>
          {order.display_id}
        </Text>
        <Text size="xs">{`訂單成立時間: ${dateUtil.format(
          order.created,
          'YYYY-MM-DD HH:mm',
        )}`}</Text>
      </div>
      <div className="divider" />
      <div className="status-section">
        <Image src={orderState.icon} style={{width: 80, height: 80}} />
        <div>
          <Text size="lg" color={config.colors.main} weight="500">
            {orderState.title}
          </Text>
          <Text size="xs">{orderState.subtitle}</Text>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding: 15px;
  border: solid 1px ${config.colors.border};
  border-radius: 4px;
  margin-bottom: 20px;

  & > .id-section {
    flex-basis: 300px;
    align-self: center;
  }

  & > .status-section {
    flex: 1;
    display: flex;
    align-items: center;
  }

  & > .divider {
    background-color: ${config.colors.border};
    flex-basis: 1px;
    margin: 0 15px;
  }

  @media only screen and (max-width: ${config.breakpoints.sm}px) {
    flex-direction: column;

    & > .divider {
      margin: 15px 0;
    }
  }
`;
