import React, {
  Fragment,
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import {OrderBlock, Row, Text, Button, Popover, Divider} from '../../Widgets';
import {Context} from '../../AppContext';
import {Link} from 'gatsby';
import {RETURN_STATUS, REFUND_TYPE, REFUND_STATUS} from '../../dictionary';
import {Alert} from 'antd';
import ReturnAppModal, {
  metadata as returnAppMeta,
} from '../../Modals/ReturnApp';
import RefundModal, {metadata as refundMeta} from '../../Modals/Refund';
import {dateUtil} from '../../Utils';
const config = require('../../data.json');

export default function AfterSaleServiceSection({order, orderItems}) {
  const [returnApps, setReturnApps] = useState([]);
  const [refunds, setRefunds] = useState([]);
  const app = useContext(Context);
  const hide = useRef(
    !order ||
      ['credit', 'extra', 'monthly'].includes(order.order_type) ||
      order.payment_status !== 'success',
  ).current;
  const allowed = useRef(
    returnApps.length === 0 && // 只能退款一次
      order.payment_status === 'success' && //付款成功
      !(
        order.display_state === 'completed' &&
        new Date() > dateUtil.offsetDay(order.complete_time, 3)
      ) && // ~ 訂單完成三日內
      !['refunded', 'voided'].includes(order.display_state), // 已退款完成、已取消
  ).current;
  const disabled = !allowed;

  const refund = refunds.length > 0 ? refunds[0] : null;
  const returnApp = returnApps.length > 0 ? returnApps[0] : null;
  let orderItem = orderItems.length > 0 ? orderItems[0] : null;

  const getReturnApps = useCallback(async () => {
    try {
      let resp = await app.actions.getReturnApps({
        order: order.id,
      });
      setReturnApps(resp.results);
    } catch (err) {
      console.warn(err);
    }
  }, [app.actions, order]);

  const getRefunds = useCallback(async () => {
    try {
      let resp = await app.actions.getRefunds({
        order: order.id,
      });
      setRefunds(resp.results);
    } catch (err) {
      console.warn(err);
    }
  }, [app.actions, order]);

  useEffect(() => {
    if (!hide) {
      getReturnApps();
      getRefunds();
    }
  }, [getReturnApps, getRefunds, hide]);

  if (hide) {
    return null;
  }

  return (
    <OrderBlock title="退款申請單狀態">
      <Row>
        <Popover
          label="上傳檔案條件說明"
          content={
            <div style={{padding: 10, maxWidth: 250}}>
              若訂單商品有瑕疵或數量短少問題，請於收到商品3日內(以物流簽收日期為準)申請退款申請服務，完成後將會收取退款手續費30元。請填寫問題並上傳瑕疵照片讓客服評估情況。逾期一律不受理不補件！
            </div>
          }
          labelStyle={{marginRight: 10}}
        />
        <Button
          disabled={disabled}
          onClick={() => {
            app.actions.setModal({
              content: (
                <ReturnAppModal
                  onUpdate={getReturnApps}
                  orderItem={orderItem}
                />
              ),
              ...returnAppMeta,
            });
          }}
          style={{marginLeft: 10}}>
          申請退款
        </Button>
      </Row>

      {returnApp && (
        <Fragment>
          <Row>
            <Text size="sm" style={{marginRight: 10}}>
              退款申請單狀態
            </Text>
            <Text color={config.colors.main}>
              {RETURN_STATUS[returnApp.status]}
            </Text>
          </Row>
          <Row>
            <Text size="sm" style={{marginRight: 10}}>
              申請原因
            </Text>
            <Text>{returnApp.rejected_reason}</Text>
          </Row>
          <Row>
            <Text size="sm" style={{marginRight: 10}}>
              備註
            </Text>
            <Text>{returnApp.note}</Text>
          </Row>
          <Row>
            <Text size="sm" style={{marginRight: 10}}>
              瑕疵商品附圖
            </Text>
            <Link to={encodeURI(returnApp.file)} target="_blank">
              {returnApp.file}
            </Link>
          </Row>
        </Fragment>
      )}

      {refund && (
        <Fragment>
          <Divider />
          <Row>
            <Text size="sm" style={{marginRight: 10}}>
              退款單狀態
            </Text>
            <Text size="sm">{REFUND_STATUS[refund.status]}</Text>
          </Row>
          <Row>
            <Text size="sm" style={{marginRight: 10}}>
              處理方式
            </Text>
            <Text size="sm">{REFUND_TYPE[refund.refund_type]}</Text>
          </Row>
          <Row>
            <Text size="sm" style={{marginRight: 10}}>
              備註
            </Text>
            <Text size="sm">{refund.note}</Text>
          </Row>
          <Row>
            <Text size="sm" style={{marginRight: 10}}>
              退款總額
            </Text>
            <Text size="sm">$ {refund.amount}</Text>
          </Row>
          {refund.refund_type === 'partial' && (
            <Fragment>
              {refund.status === 'waiting' && (
                <Row>
                  <Button
                    disabled={disabled}
                    onClick={() => {
                      app.actions.setModal({
                        content: <RefundModal onUpdate={getRefunds} />,
                        ...refundMeta,
                      });
                    }}
                    style={{marginRight: 10}}>
                    填寫退款資訊
                  </Button>
                  <Alert
                    message="若處理方式為「轉帳退款」請填入匯款資訊"
                    type="info"
                    style={{marginLeft: 10}}
                  />
                </Row>
              )}
              <Row>
                <Text size="sm" style={{marginRight: 10}}>
                  匯款銀行代碼
                </Text>
                <Text size="sm">{refund.bank_code}</Text>
              </Row>
              <Row>
                <Text size="sm" style={{marginRight: 10}}>
                  匯款分行
                </Text>
                <Text size="sm">{refund.bank_name}</Text>
              </Row>
              <Row>
                <Text size="sm" style={{marginRight: 10}}>
                  匯款戶名
                </Text>
                <Text size="sm">{refund.bank_account_name}</Text>
              </Row>
              <Row>
                <Text size="sm" style={{marginRight: 10}}>
                  匯款帳號
                </Text>
                <Text size="sm">{refund.bank_account}</Text>
              </Row>
            </Fragment>
          )}
        </Fragment>
      )}
    </OrderBlock>
  );
}
