import React from 'react';
import {OrderBlock, Row, Text} from '../../Widgets';

export default function UserInfoSection({order, cart}) {
  if (!order) {
    return null;
  }

  let date = new Date(order.complete_time);
  let lastReturnDate = new Date(date.setDate(date.getDate() + 3)).toISOString();

  return (
    <OrderBlock title="訂單資訊">
      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          會員名稱：
        </Text>
        <Text size="sm">{cart.config.name}</Text>
      </Row>

      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          手機號碼：
        </Text>
        <Text size="sm">{cart.config.phone}</Text>
      </Row>

      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          電子信箱：
        </Text>
        <Text size="sm">{cart.config.email}</Text>
      </Row>

      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          通訊地址：
        </Text>
        <Text size="sm">{cart.config.address}</Text>
      </Row>
      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          訂單備註：
        </Text>
        <Text size="sm">{order.note}</Text>
      </Row>

      {order.complete_time && (
        <Row>
          <Text size="sm" style={{marginRight: 10}}>
            訂單完成時間：
          </Text>
          <Text size="sm">
            {order.complete_time.slice(0, 16).replace('T', ' ')}
          </Text>
        </Row>
      )}
      {order.complete_time && (
        <Row>
          <Text size="sm" style={{marginRight: 10}}>
            可退款時間：
          </Text>
          <Text size="sm">
            {lastReturnDate.slice(0, 16).replace('T', ' ')}前
          </Text>
        </Row>
      )}
    </OrderBlock>
  );
}
