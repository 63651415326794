import React, {useEffect, useState, useContext} from 'react';
import {Context} from '../AppContext';
import {INVOICE_TYPES, TWO_COPIES_TYPES} from '../dictionary';

export default function useInvoiceData({order}) {
  const [records, setRecords] = useState([]);
  const isCreated = records.length > 0;

  const app = useContext(Context);

  useEffect(() => {
    (async () => {
      if (order) {
        try {
          let resp = await app.actions.getInvoices({
            order: order.id,
          });
          // expected there are only 1 invoice status === "success"
          resp = resp.results.filter((x) => x.invoice_status === 'success');
          setRecords(resp);
        } catch (err) {
          console.warn(err);
        }
      }
    })();
  }, [order, app.actions]);

  let result = {
    invoice_type: INVOICE_TYPES.two_copies.value,
    invoice_subtype: TWO_COPIES_TYPES.ezpay_vehicle.value,
    carrier_num: '', // 二連載具編號
    love_code: '', // 捐贈愛心碼
    gui_number: '', // 統編
    company_title: '',

    invoice_number: '', // 發票號碼
    create_time: '', // 發票日期
    random_num: '', // 隨機碼
  };

  if (isCreated) {
    // 已產生
    let record = records[0];
    let detail = JSON.parse(record.invoice_detail);
    result = {
      ...record,
      invoice_type: record.love_code
        ? INVOICE_TYPES.donate.value //捐贈發票
        : record.category === 'B2C'
        ? INVOICE_TYPES.two_copies.value // 二聯
        : record.category === 'B2B'
        ? INVOICE_TYPES.three_copies.value
        : null, // 三聯
      invoice_subtype: {
        0: TWO_COPIES_TYPES.mobile_vehicle.value, //手機條碼載具
        1: TWO_COPIES_TYPES.citizen_personal_certificate.value, //自然人憑證條碼載具
        2: TWO_COPIES_TYPES.ezpay_vehicle.value, //ezPay 電子發票載具
      }[record.carrier_type],
      carrier_num: record.carrier_num,
      love_code: record.love_code,
      gui_number: record.buyer_ubn,
      company_title: '',

      invoice_number: record.invoice_number,
      create_time: detail.Result.CreateTime,
      random_num: detail.Result.RandomNum,
    };
  } else {
    // 未產生，以order config代替
    let cart = JSON.parse(order.cart);
    let invoiceConfig = cart?.config?.invoiceConfig || {};

    result = {
      ...result,
      invoice_type: invoiceConfig.invoice_type,
      invoice_subtype: invoiceConfig.invoice_subtype,
      carrier_num:
        invoiceConfig.invoice_subtype === 'citizen_personal_certificate'
          ? invoiceConfig.citizen_personal_certificate_code
          : invoiceConfig.invoice_subtype === 'mobile_vehicle'
          ? invoiceConfig.mobile_vehicle_code
          : '',
      love_code: invoiceConfig.love_code,
      gui_number: invoiceConfig.gui_number,
      company_title: invoiceConfig.company_title,
    };
  }

  return {
    invoice: result,
    isCreated,
  };
}
