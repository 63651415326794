import React, {useEffect, useState, useContext} from 'react';
import {Context} from '../AppContext';
import {DELIVERY_TYPE} from '../dictionary';

export default function useLogisticData({order, logistics}) {
  const [records, setRecords] = useState(logistics || []); // logistics maybe undefined
  const isCreated = records.length > 0;

  const app = useContext(Context);

  useEffect(() => {
    (async () => {
      if (order && !logistics) {
        try {
          let resp = await app.actions.getLogistics({
            order: order.id,
          });
          setRecords(resp.results);
        } catch (err) {
          console.warn(err);
        }
      }
    })();
  }, [order, app.actions, logistics]);

  let result = {
    id: null,
    logistics_type: Object.keys(DELIVERY_TYPE)[0],
    logistics_status: '',
    tracking_number: '',
    is_delivery_private: false,
    sender_name: '',
    sender_phone: '',
    sender_tel_ext: '',
    sender_address: '',
    receiver_name: '',
    receiver_phone: '',
    receiver_tel: '',
    receiver_tel_ext: '',
    receiver_phone_spare: '',
    receiver_address: '',
    logistics_note: '',
    shipping_time: null,
    updated: '',
  };

  if (isCreated) {
    // 已產生出貨單
    let record = records[0];
    result = {
      ...record,
    };
  } else {
    // 未產生出貨單，以order config代替
    let cart = JSON.parse(order.cart);

    if (cart && cart.config) {
      result = {
        ...result,
        ...cart.config.deliveryConfig,
        logistics_type: cart.config.deliveryConfig.delivery_type,
        logistics_note: cart.config.deliveryConfig.delivery_note,
      };
    }
  }

  return {
    logistic: result,
    isCreated,
  };
}
