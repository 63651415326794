import React, {Fragment} from 'react';
import {OrderBlock, Row, Text, Divider} from '../../Widgets';
import {dateUtil} from '../../Utils';
import {PAYMENT_STATUS} from '../../dictionary';
const config = require('../../data.json');

export default function PaymentSection({order}) {
  if (!order) {
    return null;
  }

  const textColor = order.payment_status !== 'success' && config.colors.main;

  return (
    <OrderBlock title="付款資訊" color={config.colors.main}>
      <Row>
        <Text size="sm" color={textColor} style={{marginRight: 10}}>
          付款方式：
        </Text>
        <Text size="sm" color={textColor}>
          {' '}
          {
            {
              credits: '點數付款',
              neweb: '藍新付款',
              offline: '待臨櫃匯款確認',
              monthly: '月結付款',
            }[order.payment_type]
          }{' '}
          {order.payment_type === 'neweb' &&
            {
              credit: '信用卡付款',
              cvs_cod: '超商取貨付款',
              cvs: '超商繳費',
              atm: 'ATM付款',
              web_atm: '網路ATM付款',
              barcode: '超商條碼繳費',
            }[order.payment_subtype]}
        </Text>
      </Row>
      {order.payment_status !== 'success' &&
        order.payment_status !== 'failure' &&
        order.code_no && (
          <Fragment>
            <Row>
              <Text size="sm" color={textColor} style={{marginRight: 10}}>
                {
                  {
                    atm: '轉帳帳號：',
                    cvs: '超商繳費代碼：',
                  }[order.payment_subtype]
                }
              </Text>
              {order.bank_code && (
                <Text size="sm" color={textColor}>
                  {' 銀行代碼 ' + order.bank_code + ' - 帳號 '}
                </Text>
              )}
              <Text size="sm" color={textColor}>
                {' '}
                {order.code_no}
              </Text>
            </Row>
            <Row>
              <Text size="sm" color={textColor} style={{marginRight: 10}}>
                繳費期限：
              </Text>
              <Text size="sm" color={textColor}>
                {dateUtil.format(order.pay_deadline, 'YYYY-MM-DD HH:mm')}
              </Text>
            </Row>
          </Fragment>
        )}
      <Row>
        <Text size="sm" color={textColor} style={{marginRight: 10}}>
          付款狀態：
        </Text>
        <Text size="sm" color={textColor}>
          {' '}
          {PAYMENT_STATUS[order.payment_status]}
        </Text>
      </Row>
      <Divider />
      <Row>
        <Text size="sm">
          詳細繳費通知可查收E-mail信箱【藍新金流 NewebPay MPG付款交易通知書】，
          付款完成後藍新金流將發送收款通知信至您的E-mail信箱。
        </Text>
      </Row>
    </OrderBlock>
  );
}
