import React, {
  Fragment,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import styled from 'styled-components';
import {Row, Col} from 'antd';
// import GA from "../Utils/GA";
// import Pixel from "../Utils/fbPixel";
import {Context} from '../../AppContext';
import UnAuthResult from '../../Components/UnAuthResult';
import ContentWrapper from '../../Components/Layout/ContentWrapper';
import Steps from '../Cart/Steps';
import {Heading} from '../../Widgets';
import TopSection from './TopSection';
import UserInfoSection from './UserInfoSection';
import PaymentSection from './PaymentSection';
import LogisticSection from './LogisticSection';
import InvoiceSection from './InvoiceSection';
import CreditOrderSection from './CreditOrderSection';
import ExtraOrderSection from './ExtraOrderSection';
import AfterSaleServiceSection from './AfterSaleServiceSection';
import Calculation from '../../Components/Calculation';
import BottomSection from './BottomSection';
import CartItemTable from '../../Components/CartItemTable';
const queryString = require('query-string');
const appConfig = require('../../data.json');

const dbg = {
  id: '202101250311097264', //"202101270632376822"
};

export default function OrderDetail(props) {
  const {location} = props;
  const {id = dbg.id} = queryString.parse(location.search); //order.id
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(null);
  const [orderItems, setOrderItems] = useState([]);
  const [cart, setCart] = useState(null);
  const app = useContext(Context);
  const {profile} = app.state;

  const getOrder = useCallback(async () => {
    setLoading(true);
    app.actions.setLoading(true);
    if (id && profile) {
      try {
        let order = await app.actions.getOrder(id);
        setOrder(order);
        setCart(JSON.parse(order.cart));
      } catch (ex) {
        console.warn(ex);
      }
    }

    app.actions.setLoading(false);
    setLoading(false);
  }, [id, profile, app.actions]);

  const getOrderItems = useCallback(async () => {
    if (id && profile) {
      try {
        let resp = await app.actions.getOrderItems({
          order: id,
        });
        setOrderItems(resp);
      } catch (err) {
        console.warn(err);
      }
    }
  }, [id, profile, app.actions]);

  useEffect(() => {
    getOrder();
    getOrderItems();
  }, [getOrder, getOrderItems]);

  if (loading) {
    return null;
  }

  if (!profile) {
    return <UnAuthResult />;
  }

  if (!order) {
    return (
      <ContentWrapper>
        <Heading align="center" color={appConfig.colors.main}>
          無此訂單
        </Heading>
      </ContentWrapper>
    );
  }

  return (
    <ContentWrapper>
      {['default', 'extra', 'monthly'].includes(order.order_type) && (
        <Steps step={order.payment_status === 'success' ? 4 : 3} />
      )}

      {order.order_type === 'credit' ? (
        <Fragment>
          {/* 點數訂單 */}
          <PaymentSection order={order} />
          <CreditOrderSection order={order} cart={cart} />
        </Fragment>
      ) : order.order_type === 'extra' ? (
        <Fragment>
          {/* 補收款訂單 */}
          <PaymentSection order={order} />
          <ExtraOrderSection order={order} cart={cart} />
        </Fragment>
      ) : (
        <Fragment>
          <TopSection order={order} />

          {/* 購物車物品 */}
          <CartItemTable
            viewOnly={true}
            order={order}
            orderItems={orderItems}
            onUpdate={() => {
              getOrder();
              getOrderItems();
            }}
          />

          <Row gutter={[20, 20]}>
            <Col xs={24} sm={24} md={16} lg={16}>
              {/* 付款資訊 */}
              <PaymentSection order={order} />

              {/* 訂單資訊 */}
              <UserInfoSection order={order} cart={cart} />

              {/* 運送方式與配送資訊 */}
              <LogisticSection order={order} />

              {/* 發票資訊 */}
              <InvoiceSection order={order} />

              {/* 售後服務申請 */}
              <AfterSaleServiceSection order={order} orderItems={orderItems} />

              {/* 防詐騙宣導 */}

              <div style={{marginBottom: 30}}>
                提醒您目前常見之詐騙手法如下： 1.
                只要來電顯示開頭「＋」者，都是國際電話！來電顯示開頭「＋」者，是國際電話！「+886」是出自台灣的國際電話，小心辨認以防受騙！
                2.
                國內ATM自動提款機並沒有分期付款設定解除等服務，只要提及「ATM解除分期付款設定」，就一定是詐電話，切勿依來電指示操作自動提款機提至銀行提（匯）款或交付現金給任何人，以免被騙。
                3.{appConfig.information.name}
                網路平台無貨到付款服務，收取包裹前請確認查詢寄件人或公司等訂單資料，再判斷是否要領取包裹，以免受騙!瞭解更多反詐騙詳細內容請至「內政部警政署」165反詐騙網站。
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8}>
              <Calculation calculations={cart.calculations} order={order} />
            </Col>
          </Row>
        </Fragment>
      )}

      <BottomSection order={order} onUpdate={getOrder} />
    </ContentWrapper>
  );
}
