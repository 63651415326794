import {ORDER_STATE, ORDER_STATE_ICON} from '../dictionary';

export default function useOrderDisplayState({order = {}}) {
  let status = {
    title: '訂單成立',
    subtitle: '',
    step: 0,
    icon: '',
  };

  if (order) {
    let display_state = order.display_state;

    status.title = ORDER_STATE[display_state] || '-';
    status.subtitle = ORDER_STATE_ICON[display_state]?.subtitle || '-';
    status.icon = ORDER_STATE_ICON[display_state]?.icon || '';

    let phase = display_state.split('_')[0];
    status.step = {
      payment: 1,
      review: 2,
      production: 3,
      logistic: 4,
      completed: 5,
    }[phase];
  }

  return status;
}
