import React, {useContext} from 'react';
import {Context} from '../../AppContext';
import {Button, Text, Row} from '../../Widgets';
import VoidOrder, {metadata as voidMeta} from '../../Modals/VoidOrder';
import ChangeBuyer, {
  metadata as changeBuyerMeta,
} from '../../Modals/ChangeBuyer';
import Tracking from '../../Tracking';
const appConfig = require('../../data.json');

export default function BottomSection(props) {
  const app = useContext(Context);
  const {profile} = app.state;
  const {order, onUpdate} = props;

  const checkout = () => {
    app.actions.setLoading(true);

    Tracking.purchase(order);

    window.open(
      `${appConfig.endpoint.apiHost}/checkout/request/?order=${
        order.id
      }&token=${window.localStorage.getItem('token')}`,
      '_self',
    );
  };

  if (!order) {
    return null;
  }
  if (order && order.voided) {
    return null;
  }

  return (
    <Row margin="0 0 30px 0">
      {order.payment_type !== 'monthly' && order.payment_status !== 'success' && (
        <Button
          type="default"
          style={{margin: '0 5px 5px 0'}}
          onClick={() =>
            app.actions.setModal({
              content: <VoidOrder order={order} onUpdate={onUpdate} />,
              ...voidMeta,
            })
          }>
          取消訂單
        </Button>
      )}
      {profile && profile.staff_type === 'staff' && (
        <Button
          type="default"
          style={{margin: '0 5px 5px 0'}}
          onClick={() =>
            app.actions.setModal({
              content: <ChangeBuyer order={order} onUpdate={onUpdate} />,
              ...changeBuyerMeta,
            })
          }>
          變更買家
        </Button>
      )}

      <div style={{flex: 1}} />

      {order.payment_status === 'code_generated' && (
        <Text size="sm" style={{marginRight: 10}}>
          已產生付款資訊，請見付款資訊的資料欄位
        </Text>
      )}

      {order.payment_type !== 'monthly' &&
        order.payment_type !== 'offline' &&
        order.payment_status !== 'success' &&
        order.payment_status !== 'refunded' && (
          <Button
            disabled={order.payment_status === 'code_generated'}
            onClick={checkout}>
            前往付款產生繳費資訊
          </Button>
        )}
    </Row>
  );
}
