import React, {useContext, useState, Fragment, useRef} from 'react';
import {Context} from '../AppContext';
import {message} from 'antd';
import {
  Input,
  Button,
  Divider,
  Text,
  Link,
  Row,
  Heading,
  TextArea,
  Select,
  SelectOption,
} from '../Widgets';
import {RETURN_REASON} from '../dictionary';
const config = require('../data.json');

export default function ReturnAppModal(props) {
  const {orderItem, onUpdate} = props;
  const [record, _setRecord] = useState({
    id: null,
    order: null,
    status: '',
    note: '',
    file: '',
    item_index: 0,
    rejected_reason: RETURN_REASON[0],
  });
  const file = useRef();

  const app = useContext(Context);

  const submit = async () => {
    app.actions.setLoading(true);

    try {
      await app.actions.createReturnApp({
        order: orderItem.order,
        item_index: orderItem.item_index,
        status: 'pending',
        note: record.note,
        file,
        rejected_reason: record.rejected_reason,
      });
      onUpdate();
      app.actions.setModal(null);
    } catch (ex) {
      console.warn(ex);
      message.warning('建立售後單錯誤');
    }
    app.actions.setLoading(false);
  };

  function setRecord(obj) {
    _setRecord((prev) => ({...prev, ...obj}));
  }

  return (
    <div>
      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          瑕疵商品附圖
        </Text>
        <input
          onClick={(e) => e.stopPropagation()}
          type="file"
          onChange={(e) => {
            file.current = e.target.files[0];
          }}
        />
        <Text size="sm" color={config.colors.main}>
          {record.file}
        </Text>
      </Row>
      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          申請原因
        </Text>

        <Select
          value={record.rejected_reason}
          onChange={(value) => setRecord({rejected_reason: value})}
          style={{flexBasis: '250px'}}>
          {RETURN_REASON.map((x, idx) => (
            <SelectOption key={idx} value={x} style={{width: 'auto'}}>
              {x}
            </SelectOption>
          ))}
        </Select>
      </Row>
      <Row wrap="nowrap">
        <Text size="sm" style={{marginRight: 10, flex: '0 0 auto'}}>
          備註
        </Text>
        <TextArea
          value={record.note ? record.note : ''}
          onChange={(e) => setRecord({note: e.target.value})}
        />
      </Row>

      <Divider />

      <Row style={{justifyContent: 'flex-end'}}>
        <Button
          onClick={() => app.actions.setModal()}
          style={{marginRight: 10}}>
          取消
        </Button>
        <Button onClick={() => submit()}>確定</Button>
      </Row>
    </div>
  );
}

const metadata = {
  title: <Heading align="center">售後服務申請單</Heading>,
  width: '600px',
};

export {metadata};
