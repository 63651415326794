import React from 'react';
import {OrderBlock, Row, Text} from '../../Widgets';
import {DEPOSIT_STATUS} from '../../dictionary';

export default function UserInfoSection({order, cart}) {
  if (!order) {
    return null;
  }

  let paymentData = null;

  if (order.payment_transaction_detail_1) {
    paymentData = JSON.parse(order.payment_transaction_detail_1);
  }

  return (
    <OrderBlock title="儲值交易內容">
      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          加值點數費用:
        </Text>
        <Text size="sm">{cart.calculations.amount}元</Text>
      </Row>
      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          贈送點數:
        </Text>
        <Text size="sm">{cart.calculations.feedback_credits}點</Text>
      </Row>
      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          點數面額:
        </Text>
        <Text size="sm">
          {cart.calculations.feedback_credits + cart.calculations.amount}點
        </Text>
      </Row>
      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          加值狀態:
        </Text>
        <Text size="sm">{DEPOSIT_STATUS[order.payment_status]}</Text>
      </Row>
      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          建立時間:
        </Text>
        <Text size="sm">{order.created.slice(0, 19).replace('T', ' ')}</Text>
      </Row>
      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          付款時間:
        </Text>
        <Text size="sm">
          {(paymentData && paymentData.Result.PayTime) || '尚未付款'}
        </Text>
      </Row>
    </OrderBlock>
  );
}
