import React from 'react';
import {OrderBlock, Row, Text, Divider} from '../../Widgets';
import useLogisticData from '../../hooks/use-logistic-data';
import {
  LOGISTIC_STATUS,
  LOGISTIC_STATUS_SELF,
  DELIVERY_TYPE,
} from '../../dictionary';
const config = require('../../data.json');

export default function LogisticSection({order}) {
  const {logistic, isCreated} = useLogisticData({order});

  return (
    <OrderBlock title="運送方式與配送資訊">
      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          運送方式：
        </Text>
        <Text size="sm">{DELIVERY_TYPE[logistic.logistics_type]}</Text>
      </Row>
      {isCreated && (
        <Row>
          <Text size="sm" style={{marginRight: 10}}>
            理想物流單號
          </Text>
          <Text size="sm"># {logistic.id} </Text>
        </Row>
      )}
      <Row>
        <Text size="sm" style={{marginRight: 10}}>
          狀態：
        </Text>
        <Text size="sm" type="long">
          {isCreated
            ? logistic.logistics_type === 'self_pick'
              ? LOGISTIC_STATUS_SELF[logistic.logistics_status]
              : LOGISTIC_STATUS[logistic.logistics_status]
            : '尚未有物流單（物流單將在付款完成後建立）'}
        </Text>
      </Row>

      {isCreated && (
        <>
          {logistic.delivery_type === 'hct' && (
            <Row>
              <Text size="sm" style={{marginRight: 10}}>
                物流宅配單號
              </Text>
              <Text size="sm" color={config.colors.main}>
                {logistic.tracking_number || '資料尚未產生'}
              </Text>
            </Row>
          )}

          <Row>
            <Text size="sm" style={{marginRight: 10}}>
              預計出貨時間：
            </Text>
            <Text size="sm">
              {(logistic.shipping_time &&
                ` ${logistic.shipping_time.slice(
                  0,
                  10,
                )} ${logistic.shipping_time.slice(11, 16)}`) ||
                '資料尚未產生'}
            </Text>
          </Row>

          <Row>
            <Text size="sm" style={{marginRight: 10}}>
              資料最後更新時間：
            </Text>
            <Text size="sm">
              {(logistic.updated &&
                ` ${logistic.updated.slice(0, 10)} ${logistic.updated.slice(
                  11,
                  16,
                )}`) ||
                '資料尚未產生'}
            </Text>
          </Row>
        </>
      )}
      {logistic.delivery_type === 'hct' && (
        <>
          {logistic.is_delivery_private && (
            <>
              <Divider />

              <Row>
                <Text size="sm" style={{marginRight: 10}}>
                  保密代寄：
                </Text>
                <Text size="sm">是</Text>
              </Row>
              <Row>
                <Text size="sm" style={{marginRight: 10}}>
                  寄件人：
                </Text>
                <Text size="sm">{logistic.sender_name}</Text>
              </Row>

              <Row>
                <Text size="sm" style={{marginRight: 10}}>
                  寄件人手機：
                </Text>
                <Text size="sm">{logistic.sender_phone}</Text>
              </Row>

              <Row>
                <Text size="sm" style={{marginRight: 10}}>
                  寄件人電話：
                </Text>
                <Text size="sm" type="short">
                  {logistic.sender_tel}
                </Text>
                <Text size="sm">
                  {logistic.sender_tel_ext
                    ? `# ${logistic.sender_tel_ext}`
                    : ''}
                </Text>
              </Row>

              <Row>
                <Text size="sm" style={{marginRight: 10}}>
                  寄件人備用電話：
                </Text>
                <Text size="sm">{logistic.sender_phone_spare || '-'}</Text>
              </Row>

              <Row>
                <Text size="sm" style={{marginRight: 10}}>
                  寄件人地址：
                </Text>
                <Text size="sm">{logistic.sender_address}</Text>
              </Row>
            </>
          )}

          <Divider />

          <Row>
            <Text size="sm" style={{marginRight: 10}}>
              收件人：
            </Text>
            <Text size="sm">{logistic.receiver_name}</Text>
          </Row>

          <Row>
            <Text size="sm" style={{marginRight: 10}}>
              收件人手機：
            </Text>
            <Text size="sm">{logistic.receiver_phone}</Text>
          </Row>

          <Row>
            <Text size="sm" style={{marginRight: 10}}>
              收件人電話：
            </Text>
            <Text size="sm">{logistic.receiver_tel}</Text>
            <Text size="sm">
              {logistic.receiver_tel_ext
                ? `# ${logistic.receiver_tel_ext}`
                : ''}
            </Text>
          </Row>

          <Row>
            <Text size="sm" style={{marginRight: 10}}>
              收件人備用電話：
            </Text>
            <Text size="sm">{logistic.receiver_phone_spare || '-'}</Text>
          </Row>

          <Row>
            <Text size="sm" style={{marginRight: 10}}>
              收件人地址：
            </Text>
            <Text size="sm">{logistic.receiver_address}</Text>
          </Row>

          <Row>
            <Text size="sm" style={{marginRight: 10}}>
              收件備註：
            </Text>
            <Text size="sm" color={config.colors.main}>
              {logistic.logistics_note}
            </Text>
          </Row>
        </>
      )}
    </OrderBlock>
  );
}
